
import { computed, defineComponent, h, nextTick, ref, watch } from "vue";
import { NInput, NPopover } from "naive-ui";
import { onFocusElement } from "@/composables/onFocusElement";

export default defineComponent({
  props: {
    value: [String],
    disabled: {
      type: Boolean,
    },
    withError: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ["keydownTab", "deleteLink", "updateLink"],
  setup(props, { emit }) {
    const isEdit = ref(false);
    const inputRef = ref<HTMLInputElement>();
    const showTooltip = ref(false);

    watch(
      () => showTooltip.value,
      () => {
        if (showTooltip.value) {
          nextTick(() => {
            onFocusElement(inputRef);
          });
        }
      }
    );
    watch(
      () => props.loading,
      () => {
        if (showTooltip.value && !props.loading) {
          setTimeout(() => {
            onFocusElement(inputRef);
          }, 300);
        }
      }
    );
    const handleOnClick = () => {
      showTooltip.value = true;
    };
    const handleChange = () => {
      emit("updateLink", newLinkValue.value);
    };
    const handleBlur = () => {
      nextTick(() => {
        if (newLinkValue.value !== props.value) {
          handleChange();
        }
      });
    };
    const handleKeydown = (e: KeyboardEvent) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        handleChange();
      } else if (e.code === "Escape") {
        newLinkValue.value = props.value || "";
        nextTick(() => {
          inputRef.value?.blur();
        });
        showTooltip.value = false;
      } else if (e.code === "Tab") {
        showTooltip.value = false;
        emit("keydownTab", e.shiftKey);
      }
    };

    const getInputClasses = computed(() => {
      return [
        "editable-table-cell",
        "full-height",
        props.disabled && "disabled",
        isEdit.value && "bordered",
        props.value && "placeholder-view",
      ];
    });
    const newLinkValue = ref("");
    return () =>
      h(
        "div",
        {
          class: getInputClasses.value,
          onClick: () => handleOnClick(),
        },
        [
          h(
            NPopover,
            {
              show: showTooltip.value,
              placement: "right",
              showArrow: false,
              style: { minWidth: "366px" },
              class: "link-tooltip",
              onClickoutside: () => {
                if (showTooltip.value) {
                  showTooltip.value = false;
                }
              },
            },
            {
              trigger: () =>
                h("div", [
                  props.value
                    ? h(
                        "div",
                        {
                          class: "link",
                        },
                        "Link"
                      )
                    : h(
                        "div",
                        {
                          class: "placeholder",
                        },
                        "Enter"
                      ),
                ]),
              default: () =>
                h(
                  "div",
                  {
                    class: [
                      "link-input--container",
                      props.loading && "link-input--container-loading",
                    ],
                  },
                  [
                    h("div", { class: "link-input--wrapper" }, [
                      props.value
                        ? h("div", {}, [
                            h(
                              "a",
                              {
                                class: "link",
                                target: "_blank",
                                href: props.value,
                              },
                              props.value
                            ),
                          ])
                        : h(NInput, {
                            value: newLinkValue.value,
                            class: ["input-borderless", "input-tooltip-link"],
                            placeholder: "Enter link",
                            ref: inputRef,
                            onUpdateValue: (value: string) => {
                              newLinkValue.value = value;
                            },
                            onKeydown: (e: KeyboardEvent) => {
                              handleKeydown(e);
                            },
                            onBlur: handleBlur,
                          }),
                    ]),
                    props.value &&
                      h(
                        "button",
                        {
                          class: "button-remove",
                          onClick: () => {
                            emit("deleteLink");
                            newLinkValue.value = "";
                            nextTick(() => {
                              onFocusElement(inputRef);
                            });
                          },
                        },
                        "Remove"
                      ),
                  ]
                ),
            }
          ),
        ]
      );
  },
});
