import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79666aa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "costing-parameter-details buy-items--wrapper",
  ref: "buyItemsWrapperRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.initLoading }, null, 8, ["loading"]),
    ($setup.showCannotDeleteModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 0,
          class: "info-modal cannot-delete-modal",
          show: $setup.showCannotDeleteModal,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showCannotDeleteModal) = $event)),
          title: "Cannot Delete",
          text: "You cannot delete costing parameter that is connected to existing parts costing.",
          infoModal: true,
          onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showCannotDeleteModal = false))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true),
    ($setup.showBuyItemModal)
      ? (_openBlock(), _createBlock($setup["AddBuyItemModal"], {
          key: 1,
          show: $setup.showBuyItemModal,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => (($setup.showBuyItemModal) = $event)),
          mfrRegions: $setup.mfrRegions,
          nameOptions: $setup.buyItemsNameArray,
          manufacturerOptions: $setup.buyItemsManufacturerArray,
          manufacturerPNOptions: $setup.buyItemsManufacturerPNArray,
          linkOptions: $setup.buyItemsManufacturerLinkArray,
          onUpdate: $setup.fetchData,
          onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.showBuyItemModal = false))
        }, null, 8, ["show", "mfrRegions", "nameOptions", "manufacturerOptions", "manufacturerPNOptions", "linkOptions"]))
      : _createCommentVNode("", true),
    _createVNode($setup["CostingParametersOptions"], {
      showAddLine: "",
      onSelectPlusOption: $setup.onSelectPlusOption
    }),
    _createElementVNode("div", {
      class: "buy-items-table--wrapper",
      ref: "tableRef",
      style: _normalizeStyle({minHeight: `${$setup.maxHeight}px`})
    }, [
      (!$setup.initLoading)
        ? (_openBlock(), _createBlock($setup["NDataTable"], {
            key: 0,
            class: _normalizeClass(["buy-items-table costing-parameters-table", {'disable-scroll': $setup.validationError || $setup.isEditModeCell}]),
            columns: $setup.columns,
            data: $setup.buyItemData,
            "single-line": false,
            "max-height": $setup.maxHeight - $setup.tableHeaderHeight,
            style: _normalizeStyle({height: `${$setup.maxHeight}px`})
          }, null, 8, ["columns", "data", "max-height", "style", "class"]))
        : _createCommentVNode("", true)
    ], 4)
  ], 512))
}