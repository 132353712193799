import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13dbf8a5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wrapper",
  ref: "modalRef"
}
const _hoisted_2 = { class: "main-form" }
const _hoisted_3 = { class: "link-input--wrapper" }
const _hoisted_4 = {
  key: 1,
  class: "link-input--container"
}
const _hoisted_5 = { class: "link-input--link-wrapper" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { class: "main-form" }
const _hoisted_8 = { class: "title" }
const _hoisted_9 = {
  key: 0,
  class: "cost-and-lead-time-info"
}
const _hoisted_10 = { class: "add-raw-material-modal-actions costing-parameters-action-modal-actions main-modal-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "add-raw-material-modal costing-parameters-action-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Add Buy item",
    onMaskClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('close', $setup.isUpdateTable)))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["NForm"], {
          class: "add-raw-material-form costing-parameters-action-form",
          ref: "formRef",
          model: $setup.form
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["NFormItem"], {
                required: "",
                path: "name",
                label: "Name",
                rule: $setup.requiredRules('Name', 'name')
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.name,
                    options: $setup.nameOptions,
                    addOptionFunction: (data) => $setup.onAddOptionFunction(data, 'name', $setup.nameOptions),
                    changeOptionFunction: (data) => $setup.changeOptionFunction(data, 'name'),
                    showDeleteOption: false,
                    onUpdateValue: (data) => $setup.form.name = data,
                    filterable: "",
                    addable: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "addOptionFunction", "changeOptionFunction", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }, 8, ["rule"]),
              _createVNode($setup["NFormItem"], {
                path: "manufacturer",
                label: "Manufacturer"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.manufacturer,
                    options: $setup.manufacturerOptions,
                    addOptionFunction: (data) => $setup.onAddOptionFunction(data, 'manufacturer', $setup.manufacturerOptions),
                    changeOptionFunction: (data) => $setup.changeOptionFunction(data, 'manufacturer'),
                    showDeleteOption: false,
                    onUpdateValue: (data) => $setup.form.manufacturer = data,
                    filterable: "",
                    addable: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "addOptionFunction", "changeOptionFunction", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }),
              _createVNode($setup["NFormItem"], {
                path: "manufacturerPN",
                label: "Manufacturer PN"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["SelectableTableCell"], {
                    value: $setup.form.manufacturerPN,
                    options: $setup.manufacturerPNOptions,
                    addOptionFunction: (data) => $setup.onAddOptionFunction(data, 'manufacturerPN', $setup.manufacturerPNOptions),
                    changeOptionFunction: (data) => $setup.changeOptionFunction(data, 'manufacturerPN'),
                    showDeleteOption: false,
                    onUpdateValue: (data) => $setup.form.manufacturerPN = data,
                    filterable: "",
                    addable: "",
                    selectInForm: "",
                    onKeydownTab: $setup.onKeydownTabHandler
                  }, null, 8, ["value", "options", "addOptionFunction", "changeOptionFunction", "onUpdateValue", "onKeydownTab"])
                ]),
                _: 1
              }),
              _createVNode($setup["NFormItem"], {
                path: "link",
                label: "Link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    ($setup.showLinkInput)
                      ? (_openBlock(), _createBlock($setup["NInput"], {
                          key: 0,
                          class: "tabbable-focusable",
                          value: $setup.form.link,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.link) = $event)),
                          placeholder: "Enter",
                          onKeydown: $setup.onKeydown,
                          onBlur: _cache[1] || (_cache[1] = ($event: any) => ($setup.form.link = $setup.onCleanExtraSpaces($setup.form.link), $setup.showLinkInput = !$setup.form.link))
                        }, null, 8, ["value"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("a", {
                              class: "link link-input--link tabbable-focusable link-focusable",
                              target: "_blank",
                              href: $setup.form.link
                            }, _toDisplayString($setup.form.link), 9, _hoisted_6)
                          ]),
                          _createElementVNode("button", {
                            class: "link-input--button-remove tabbable-focusable button-focusable",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.form.link = '', $setup.showLinkInput = true)),
                            onKeydown: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($setup.onKeydownTab && $setup.onKeydownTab(...args)), ["tab"]))
                          }, "Remove", 32)
                        ]))
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model"]),
        _createVNode($setup["NForm"], {
          class: "mfr-regions-form costing-parameters-action-form",
          ref: "formMfrRegionsRef",
          model: $setup.formMfrRegions
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formMfrRegions, (value, key) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(key) + " manufacturing region", 1),
                _createVNode($setup["NFormItem"], {
                  path: `${key}.available`,
                  label: "Availability"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["SelectableTableCell"], {
                      value: $setup.formMfrRegions[key].available ? 'Yes' : 'No',
                      options: $setup.availabilityOptions,
                      onUpdateValue: (data) => $setup.formMfrRegions[key].available = data === $setup.AvailabilityValueEnum.Yes,
                      selectInForm: "",
                      onKeydownTab: $setup.onKeydownTabHandler
                    }, null, 8, ["value", "options", "onUpdateValue", "onKeydownTab"])
                  ]),
                  _: 2
                }, 1032, ["path"]),
                (value.available)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.MOQ`,
                        required: "",
                        label: "MOQ",
                        rule: $setup.baseCostNumberFieldRules(`${key} MOQ`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInputNumber"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].MOQ,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].MOQ) = $event),
                            placeholder: "Enter",
                            showButton: false,
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, null, 8, ["value", "onUpdate:value", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "rule"]),
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.cost`,
                        required: "",
                        label: `Cost (pc.)(${$setup.formMfrRegions[key].mfrRegionCurrencySign})`,
                        rule: $setup.baseCostNumberFieldRules(`${key} cost`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInput"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].cost,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].cost) = $event),
                            placeholder: "Enter",
                            showButton: false,
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, {
                            prefix: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.formMfrRegions[key].mfrRegionCurrencySign), 1)
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "label", "rule"]),
                      _createVNode($setup["NFormItem"], {
                        path: `${key}.leadTime`,
                        required: "",
                        label: "Lead time (Days)",
                        rule: $setup.baseCostNumberFieldRules(`${key} lead time`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["NInputNumber"], {
                            class: "tabbable-focusable",
                            value: $setup.formMfrRegions[key].leadTime,
                            "onUpdate:value": ($event: any) => (($setup.formMfrRegions[key].leadTime) = $event),
                            placeholder: "Enter",
                            showButton: false,
                            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
                          }, null, 8, ["value", "onUpdate:value", "onKeydown"])
                        ]),
                        _: 2
                      }, 1032, ["path", "rule"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 256))
          ]),
          _: 1
        }, 8, ["model"]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode($setup["NButton"], {
            class: "update-button n-button-save medium-button tabbable-focusable",
            type: "info",
            onClick: $setup.onAddBuyItem,
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Add")
            ]),
            _: 1
          }, 8, ["onKeydown"]),
          _createVNode($setup["NButton"], {
            class: "medium-button n-button-cancel tabbable-focusable",
            type: "tertiary",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close'))),
            onKeydown: _withKeys($setup.onKeydownTab, ["tab"])
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onKeydown"])
        ])
      ], 512)
    ]),
    _: 1
  }))
}